import React from "react"
import Button from "@hui/_atoms/button/Button";
import Modal from "@hui/_molecules/modal/Modal";
import I18n from "../../utilities/translations";
import { delinkScreens } from "./types";

interface iHowToProceedModal {
  howToProceedModalOpen: boolean;
  setHowToProceedModalOpen: (boolean) => void;
  setFlowState: (string: delinkScreens) => void;
}

const ctx = "home.call_to_action.delinked.how_to_proceed";
const taxAgencyName = I18n.t("global.tax_collection_authority_short")

const HowToProceedModal = ({ howToProceedModalOpen, setHowToProceedModalOpen, setFlowState }: iHowToProceedModal) => {
  const handleInterimDelinked = () => {
    setFlowState("interim_delinked")
    setHowToProceedModalOpen(false)
  };

  const handleIntentionallyDelinked = () => {
    setFlowState("intentionally_delinked")
    setHowToProceedModalOpen(false)
  }

  return (
    <Modal
      closable={true}
      open={howToProceedModalOpen}
      setOpen={setHowToProceedModalOpen}
      title={I18n.t("title", { scope: ctx })}
      includesFooter={false}
    >
      {/* eslint-disable-next-line xss/no-mixed-html */}
      <div className="list-disc tw-prose-sm tw-prose-grey" dangerouslySetInnerHTML={{ __html: I18n.t("paragraph_html", { scope: ctx, tax_agency: taxAgencyName }) }} />
      <div className="tw-flex tw-gap-4 tw-flex-col mt-1">
        <Button variant="primary" onClick={handleInterimDelinked}>{I18n.t("interim_delinked_button", { scope: ctx })}</Button>
        <Button variant="secondary" onClick={handleIntentionallyDelinked}>{I18n.t("intentionally_delinked_button", { scope: ctx })}</Button>
      </div>
    </Modal>
  )
}

export default HowToProceedModal
