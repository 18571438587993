import React, { useState } from "react";
import Button from "@hui/_atoms/button/Button";
import Modal from "@hui/_molecules/modal/Modal";
import SalesTaxOnlyConsentToAct from "@hui/consents/consent_to_act/SalesTaxOnlyConsentToAct";
import I18n from "../../utilities/translations";
import { taxAgencyAuthorisationLinkSalesTaxOnly } from "../../API/tax_agency_authorisation.api";
import { taxAgencyAuthorisation as taa } from "../../types/taxAgencyAuthorisation.type";
import { delinkScreens } from "./types";

interface iLinkSalesTaxOnlyModal {
  linkSalesTaxOnlyModalOpen: boolean;
  setFlowState: (string: delinkScreens) => void;
  setLinkSalesTaxOnlyModalOpen: (boolean) => void;
  taxAgencyAuthorisation: taa;
}

const translationOptions = {
  scope: "home.call_to_action.delinked.link_sales_tax_only",
  tax_agency: I18n.t("global.tax_collection_authority_short"),
  sales_tax: I18n.t("global.sales_tax")
}

const LinkSalesTaxOnlyModal = ({ linkSalesTaxOnlyModalOpen, setFlowState, setLinkSalesTaxOnlyModalOpen, taxAgencyAuthorisation }: iLinkSalesTaxOnlyModal) => {
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const handleSubmit = async () => {
    try {
      const response = await taxAgencyAuthorisationLinkSalesTaxOnly(taxAgencyAuthorisation.id);

      if (response.success) {
        setLinkSalesTaxOnlyModalOpen(false);
      }
      Turbolinks.visit(window.location.href)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("User unable to update tax agency authorisation", { error });
    }
  };

  const handleBack = () => {
    setLinkSalesTaxOnlyModalOpen(false)
    setFlowState("sales_tax_only_consent")
  }

  const handleScrolling = modal => {
    const { scrollTop, scrollHeight } = modal;
    const { height } = modal.getBoundingClientRect();
    const threshold = scrollHeight - height - 50; // Buffer of 50px

    if (scrollTop >= threshold) {
      setConfirmDisabled(false);
    }
  };

  window.addEventListener("scroll", (event) => handleScrolling(event.target), true);

  return (
    <Modal
      closable={true}
      open={linkSalesTaxOnlyModalOpen}
      setOpen={setLinkSalesTaxOnlyModalOpen}
      title={I18n.t("title", translationOptions)}
      includesFooter={false}
    >
      <SalesTaxOnlyConsentToAct />
      <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row-reverse tw-justify-between mt-1 hnry-dialog-panel-actions">
        <Button variant="primary" disabled={confirmDisabled} onClick={handleSubmit}>{I18n.t("button", translationOptions)}</Button>
        <Button variant="secondary" onClick={handleBack}>Back</Button>
      </div>
    </Modal >
  )
}

export default LinkSalesTaxOnlyModal
