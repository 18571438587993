import { putJson } from "./config/fetch.api";

type offBoardReasons = "nlse" | "alt_arrangement" | "overseas_resident" | "graduated";
type updateContexts = "linked" | "interim_delinked" | "intentionally_delinked" | "off_board_user" | "link_sales_tax_only";
interface iTaxAgencyUpdate {
  id: number;
  context: updateContexts;
  offBoardReason?: offBoardReasons;
}

interface tAAResponse {
  success: boolean
}

const taxAgencyAuthorisationUpdate = async ({ id, context, offBoardReason }: iTaxAgencyUpdate) => (
  putJson(Routes.tax_agency_authorisation_path(id), JSON.stringify({ id, context, off_board_reason: offBoardReason })));

export const taxAgencyAuthorisationLinked = (id: number): Promise<tAAResponse> => taxAgencyAuthorisationUpdate({ id, context: "linked" });

export const taxAgencyAuthorisationInterimDelinked = (id: number): Promise<tAAResponse> => taxAgencyAuthorisationUpdate({ id, context: "interim_delinked" });

export const taxAgencyAuthorisationIntentionallyDelinked = (id: number): Promise<tAAResponse> => taxAgencyAuthorisationUpdate({ id, context: "intentionally_delinked" });

export const taxAgencyAuthorisationLinkSalesTaxOnly = (id: number): Promise<tAAResponse> => taxAgencyAuthorisationUpdate({ id, context: "link_sales_tax_only" });

export const taxAgencyAuthorisationOffBoardUser = (
  id: number,
  offBoardReason: offBoardReasons
): Promise<tAAResponse> => taxAgencyAuthorisationUpdate({ id, context: "off_board_user", offBoardReason });
