import React from "react";
import I18n from "../../utilities/translations";
import { taxAgencyAuthorisationStates as states } from "../../types/taxAgencyAuthorisation.type";


const RelinkBannerContent = ({ currentState }: { currentState: states }) => {
  const translationOptions = {
    scope: `home.call_to_action.${currentState}.banner`,
    tax_agency: I18n.t("global.tax_collection_authority_short")
  }

  return (
    <>
      {(currentState === "interim_delinked") && (
        <p>
          { I18n.t("paragraph", translationOptions) }
        </p>
      )}
    
      {(currentState === "link_sales_tax_only") && (
        <>
          <p>
            { I18n.t("paragraph_1", translationOptions) }
          </p>
          <p>
            { I18n.t("paragraph_2", translationOptions) }
          </p>
        </>
      )}
    </>
  )
};

export default RelinkBannerContent
