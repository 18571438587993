import React, { useEffect, useState } from "react"
import { CountryIso2, CountrySelector } from "react-international-phone";
import classNames from "classnames";
import "react-international-phone/style.css";


export interface iphNumberType  {
  prefix: string,
  number: string
  isoCode?: CountryIso2
}

interface iMobileNumberInput {
    value?: iphNumberType;
    onChange?: (...args) => void;
    name: string
    required: boolean
    countryDisabled?: boolean
}

export default function PhoneNumberInput({onChange, name, required, value, countryDisabled = true}: iMobileNumberInput) {

  const [country, setCountry] = useState<{ isoCode: CountryIso2, dialCode: string}>({dialCode:value?.prefix || "44", isoCode: value?.isoCode || "gb"})
  const [number, setNumber] = useState(value?.number || "")
  
  useEffect( () => {
    if (number && country?.dialCode) {
      const {dialCode} = country
      onChange({number, prefix: dialCode})
    }
  }, [number, country, onChange])

  return (
    <div className="tw-min-w-full no-bs tw-mt-2 " id="phone-input-wrap">
      <label
        htmlFor={name}
        className={classNames("hnry-label",{
          "after:tw-content-['*'] after:tw-inline after:tw-text-red after:tw-ml-1":
              required,
        })}
      >
        Phone Number
      </label>

      <div className="tw-grid
        tw-grid-cols-12
        tw-rounded-md 
        tw-bg-white 
        tw-outline tw-outline-1 
        tw--outline-offset-1 
        tw-outline-gray-300 
        has-[input:focus-within]:tw-outline
        has-[input:focus-within]:tw-outline-2 
        has-[input:focus-within]:tw--outline-offset-2">

        <div className="tw-col-span-2">
          <div className="tw-flex tw-flex-col tw-shrink-0 tw-grid-cols-1 focus-within:tw-relative">
            <CountrySelector
              className="tw-flex-1
              !tw-border-none
              tw-row-start-1
              tw-w-full 
              tw-appearance-none 
              tw-py-1.5 
              tw-pl-3 
              tw-text-base 
              tw-text-gray-500
              placeholder:tw-text-gray-400 focus:tw-outline focus:tw-outline-2 focus:tw--outline-offset-2 
              focus:tw-outline-indigo-600 sm:tw-text-sm/6
              tw-min-w-full tw-min-h-full !tw-border-0 tw-rounded-md"
              selectedCountry={country.isoCode}
              disabled={countryDisabled}
              buttonClassName="tw-border-0"
              onSelect={(count) => {                
                setCountry({isoCode: count.iso2,  dialCode: count.dialCode})}}
            />
          </div>
        </div>

        <div className="tw-col-span-10 tw-flex tw-flex-row">
          <div className="tw-align-middle tw-flex tw-items-center tw-justify-center">(+{country.dialCode})</div>
          <div className="tw-min-h-full tw-flex tw-flex-col tw-flex-1">
            <input id="phone-input"
              className="no-bs !tw-border-0 tw-flex-1 tw-pl-4 !tw-m-0 !tw-ring-0 tw-text-gray-800"
              type="tel"
              value={number}
              onChange={(e) => setNumber && setNumber(e?.target?.value)}
            />
          </div>
        </div>

      </div>
    </div>
  )
}