import React from "react"
import Button from "@hui/_atoms/button/Button";
import Modal from "@hui/_molecules/modal/Modal";
import I18n from "../../utilities/translations";
import { delinkScreens } from "./types";

interface iContinueWithHnryModal {
  continueWithHnryModalOpen: boolean;
  setContinueWithHnryModalOpen: (boolean) => void;
  setFlowState: (string: delinkScreens) => void;
}

const ctx = "home.call_to_action.delinked.continue_with_hnry"
const taxAgencyName = I18n.t("global.tax_collection_authority_short")

const ContinueWithHnryModal = ({ continueWithHnryModalOpen, setContinueWithHnryModalOpen, setFlowState }: iContinueWithHnryModal) => {
  const handleYes = () => {
    setFlowState("allow_to_relink")
    setContinueWithHnryModalOpen(false)
  };

  const handleNo = () => {
    setFlowState("off_board_user")
    setContinueWithHnryModalOpen(false)
  }

  return (
    <Modal
      closable={true}
      open={continueWithHnryModalOpen}
      setOpen={setContinueWithHnryModalOpen}
      title={I18n.t("title", { scope: ctx })}
      includesFooter={false}
    >
      <div>
        {I18n.t("paragraph", { tax_agency: taxAgencyName, scope: ctx })}
      </div>
      <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row-reverse tw-justify-between mt-1 hnry-dialog-panel-actions">
        <Button variant="primary" onClick={handleYes}>Yes</Button>
        <Button variant="secondary" onClick={handleNo} disabled={true}>No</Button>
      </div>
    </Modal>)
}

export default ContinueWithHnryModal
