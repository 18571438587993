import React, { useState } from "react";
import Button from "@hui/_atoms/button/Button"
import I18n from "../../utilities/translations";
import LinkedModal from "./LinkedModal";
import RelinkBannerContent from "./RelinkBannerContent";
import { taxAgencyAuthorisation as taa, taxAgencyAuthorisationStates as states } from "../../types/taxAgencyAuthorisation.type";

const RelinkBanner = ({ taxAgencyAuthorisation, currentState }: { taxAgencyAuthorisation: taa, currentState: states }) => {
  const [linkedModalOpen, setLinkedModalOpen] = useState(false)
  const translationOptions = {
    scope: `home.call_to_action.${currentState}.banner`,
    tax_agency: I18n.t("global.tax_collection_authority_short")
  }

  return (
    <>
      <div className="alert alert-new" role="alert" >
        <h2 className="hnry-heading hnry-heading--marketing-h1">{I18n.t("title", translationOptions)}</h2>
        <RelinkBannerContent currentState={currentState}/>
        <Button type="button" onClick={() => setLinkedModalOpen(true)}>
          {I18n.t("button", translationOptions)}
        </Button>
      </div>
      {linkedModalOpen && (
        <LinkedModal 
          linkedModalOpen={linkedModalOpen}
          setLinkedModalOpen={setLinkedModalOpen}
          taxAgencyAuthorisation={taxAgencyAuthorisation}
        />
      )}
    </>
  )
};

export default RelinkBanner
