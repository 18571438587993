import React, { useCallback, useEffect, useState } from "react";
import Input from "@hui/_atoms/input/Input";
import AddressAutocompleteHui from "@hui/_organisms/address_autocomplete/AddressAutocompleteHui";
import { Address } from "@hui/_organisms/address_autocomplete/types";
import JobCategorySelectionHui, { iJobCategory } from "@hui/utils/JobCategorySelector/JobCategorySelectorHui";
import BlockingModal from "@hui/_molecules/blocking_modal/BlockingModal";
import { User } from "app/javascript/global";
import Button from "@hui/_atoms/button/Button";
import ensureJsonFieldsAreNotEmpty from "@hui/utils/jsonInspectionHelpers";
import { waitlistInformationUK } from "../../../API/waitlistInfoUk.api";
import moreActions from "../Waitlist/MenuActions";

// Things we need to get in here to make things work
// Job categories api request to populate when rendered
// Google Places API Key
const WaitlistInformation = ({ formValues, jobCategories, jobCategoriesPresent, fallbackOptions, nativeApp, updatedFields}:
    { formValues: Address, jobCategories: iJobCategory[], jobCategoriesPresent: boolean, fallbackOptions: { id: number, title: string }, nativeApp, currentUser: User, 
    updatedFields: (updatedAddress: Address, updatedFirstName: string) => void}) => {
  const [jobType, setJobType] = useState<{ id: number, other: string }>()
  const [firstName, setFirstName] = useState<string>()
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [currentAddress, setCurrentAddress] = useState(formValues)


  const requiredFieldsForAddress: (keyof typeof currentAddress)[]  = ["postTown", "postcode", "country"]


  const handleAddressChange = useCallback((data: Address) => {
    if (data !== currentAddress) {
      setCurrentAddress(data)
    }
  }, []);

  // Bodge to make things work
  const formNames = {
    address_line_1: "#{form_name_prefix}[address_line_1]",
    city: "#{form_name_prefix}[city]",
    country: "#{form_name_prefix}[country]",
    google_place_id: "#{form_name_prefix}[google_place_id]",
    post_town: "#{form_name_prefix}[post_town]",
    postcode: "#{form_name_prefix}[postcode]",
    state: "#{form_name_prefix}[state]",
    street_address: "#{form_name_prefix}[street_address]",
    street_number: "#{form_name_prefix}[street_number]",
    suburb: "#{form_name_prefix}[suburb]",
  }

  const submitForm = async () => {
    setIsLoading(true)
    try {
      if (!currentAddress) {
        throw new Error("Please enter address")
      }
      const {data} = await waitlistInformationUK({
        jobType, address: currentAddress, firstName
      })
      updatedFields(data.address, data.firstName)
      setIsLoading(false)

    } catch (err) {
      setIsLoading(false)
      toastr.error("We're sorry, something went wrong, could you please try again")
      throw err
    }
  }

  const validateFormWhenOtherIsSelected = (name: string, add: Address, jobTypeDescription: string): boolean => 
    !!(name && ensureJsonFieldsAreNotEmpty(add, requiredFieldsForAddress) && jobTypeDescription)

  const validateForm = (name: string, add: Address, jobTypeId: number): boolean => !!((name && ensureJsonFieldsAreNotEmpty(add, requiredFieldsForAddress) && (jobCategoriesPresent || jobTypeId)))

  useEffect(() => {
    // if other is selected need to have bespoke logic applied

    const shouldValidate = jobType?.id === fallbackOptions?.id ?
      validateFormWhenOtherIsSelected(firstName, currentAddress, jobType?.other) :
      validateForm(firstName, currentAddress, jobType?.id)

    if (shouldValidate) {
      setIsDisabled(false)
    } else if (!isDisabled) {
      setIsDisabled(true)
    }
  }, [firstName, isDisabled, jobType, currentAddress]);

  function getSelectedJobType(data: { id: number, other: string }) {
    setJobType(data)
  }

  return (
    <>
      <BlockingModal
        hideOverlay={true}
        title={"Tell us a bit about yourself"}
        moreActions={moreActions({ nativeApp })}
        modal={false}
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <div className="tw-flex tw-flex-col tw-min-h-[30vh] md:tw-min-h-[40vh]">

          <form className="tw-flex-1">
            <div className="tw-min-h-full tw-grow">
              <div className="tw-grid tw-grid-rows-12 tw-gap-8">
                <div className="tw-row-span-9">
                  <div className="tw-mt-4 tw-mb-2">
                    <Input label="Preferred Name" placeholder="Let us know how we should refer to you" required name="First Name" setValue={setFirstName} autocomplete="given-name" />
                  </div>
                  {!jobCategoriesPresent ? (<div className="tw-mb-2">
                    <JobCategorySelectionHui
                      categories={jobCategories}
                      fallbackOption={fallbackOptions}
                      label={"What do you do for work?"}
                      required={true}
                      name={"Job Category"}
                      placeholder={"e.g. Florist"}
                      value={0}
                      returnSelectedValue={getSelectedJobType} />
                  </div>) : null}
                  <div className="tw-mb-8">
                    <AddressAutocompleteHui
                      addressRequired={true}
                      jurisdictionCode="uk"
                      formNames={formNames}
                      formValues={currentAddress}
                      showMoreDetails={false}
                      displayLabel="Address"
                      onChange={handleAddressChange}
                    />
                  </div>
                </div>
                <div className="tw-row-span-3 tw-pt-10">
                  <div className="tw-justify-center tw-h-full tw-flex tw-items-center">
                    <Button
                      type="button"
                      onClick={submitForm}
                      loading={isLoading}
                      variant="primary"
                      disabled={isDisabled}
                      classes="tw-min-w-full"
                    >
                      Continue             
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </BlockingModal>
    </>
  )
}
export default WaitlistInformation