import React, { useEffect } from "react";
import Input from "../../_atoms/input/Input";

type fieldType = { 
  label: string,
  value: string
}

interface iCOPWarningModal {
  fields: fieldType[];
  subheading: string;
}

const COPWarningModal = ({fields, subheading}: iCOPWarningModal) => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('COPWarningModal mounted');
  })

  return (
    <div className="tw-flex tw-justify-between tw-flex-col">
      <p className="!tw-text-gray-700 tw-mb-4">{subheading}</p>
      {fields.length > 0 ? fields.map(({ label, value }) => (
        <Input
          inputClasses="tw-mb-4"
          key={`${label}-field`}
          disabled
          readOnly
          label={label}
          value={value} 
        />
      )) : null}
    </div>
  );
};

export default COPWarningModal;