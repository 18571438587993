import React from "react";
import Button from "@hui/_atoms/button/Button"
import I18n from "../../utilities/translations";
import { delinkScreens } from "./types";

const ctx = "home.call_to_action.delinked.banner"
const taxAgencyName = I18n.t("global.tax_collection_authority_short")

const DelinkedBanner = ({ setFlowState }: { setFlowState: (string: delinkScreens) => void }) => (
  <div className="alert alert-danger" role="alert" >
    <h2 className="hnry-heading hnry-heading--marketing-h1">{I18n.t("title", { tax_agency: taxAgencyName, scope: ctx })}</h2>
    <p>
      {I18n.t("paragraph", { tax_agency: taxAgencyName, scope: ctx })}
    </p>
    <Button type="button" onClick={() => setFlowState("continue_with_hnry")}>
      {I18n.t("button", { scope: ctx })}
    </Button>
  </div>
);

export default DelinkedBanner
