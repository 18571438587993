import React, { useEffect, useState } from "react";
// import toastr from "";
import { createPayee, iCreatePayeeResponseBodyType, iCreatePayee, iScaChallengeSubmit, postScaChallenge, putScaChallenge } from "../../../API/cop.api";
import Modal from "../../_molecules/modal/Modal";
import I18n from "../../../utilities/translations";
import Icon from "../../_atoms/icons/icon/Icon";
import SCAConfirm from "../sca_confirm/SCAConfirm";
import COPWarningModal from "./COPWarningModal";
import COPErrorModal from "./COPErrorModal";
import Button from "../../_atoms/button/Button";

type fieldType = {
  label: string,
  value: string
}

interface iCOPModal {
  fields: fieldType[];
  recordAttributes: unknown;
  actionName: string;
  submissionPath?: string;
  submissionMethod?: string;
  userId: number;
  callBackUrl?: string;
  recordName: string;
  payeeName: string;
  sortCode: string;
  accountNumber: string;
  accountType: string;
}

const COPModal = ({ 
  fields,
  recordAttributes,
  actionName,
  userId,
  callBackUrl,
  submissionMethod,
  submissionPath,
  recordName,
  payeeName,
  sortCode,
  accountNumber,
  accountType }: iCOPModal) => {
  const [show, setShow] = useState(false);
  const [showSCA, setShowSCA] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showEdenredError, setShowEdenredError] = useState(false);
  const [showSortCodeField, setShowSortCodeField] = useState(false);
  const [showPayeeNameField, setShowPayeeNameField] = useState(false);
  const [showPayeeAccountNumberField, setShowPayeeAccountNumberField] = useState(false);
  const [showPayeeAccountTypeField, setShowPayeeAccountTypeField] = useState(false);
  const [deviceName, setDeviceName] = useState<string>();
  const [challengeId, setChallengeId] = useState<string>();
  const [createResponseBody, setCreateResponseBody] = useState<iCreatePayeeResponseBodyType>();

  const sortCodeWarnings = [
    "SCNF",
    "SCNS",
  ]

  const payeeNameWarnings = [
    "MBAM",
    "BAMM",
    "PAMM",
    "ANNM",
    "ACNS",
    "OPTO",
    "CASS",
  ]

  const payeeAccountTypeWarnings = [
    "BANM",
    "PANM",
    "BAMM",
    "PAMM",
  ]

  const payeeAccountNumberWarnings = [
    "ACNS",
    "OPTO",
    "CASS",
  ]

  const secondaryIdentificationErrors = [
    "IVCR",
  ]

  const payeeAccountNumberErrors = [
    "AC01",
  ]

  const edenredErrors = [
    "CWS0365",
    "CWS0360",
    "CWS0361",
    "CWS0362",
    "CWS0363",
    "CWS0366",
    "CWS0359",
    "CWS0279",
    "CWS0050",
  ]

  const payeeExistsErrors = [
    "CWS0308",
  ]

  const callScaChallenge = async () => {
    const scaChallengeBody: iScaChallengeSubmit = {
      context: actionName,
      data: {
        [recordName]: recordAttributes,
        payee: { confirmation_required: true },
        confirm_payee: {
          create_request_external_ref: createResponseBody?.payee_external_ref,
          payee_suggestion: {
            payee_name: createResponseBody?.payee_suggestion?.payee_name,
            account_type: createResponseBody?.payee_suggestion?.account_type,
          },
          accept_suggestion: false,
          payee_details: {
            payee_name: payeeName,
            sort_code: sortCode,
            account_number: accountNumber,
            name_verification_details: {
              account_type: accountType
            }
          },
        },
        challenge: {
          callback_url: callBackUrl,
        }
      }
    }

    try {
      const res = submissionMethod === "post" ? await postScaChallenge(submissionPath, scaChallengeBody) : await putScaChallenge(submissionPath, scaChallengeBody)
      setDeviceName(res.data?.device_registration.name);
      setChallengeId(res.data?.mobile_based_verification.external_challenge_identifier);
      setShow(false);
      setShowSCA(true);
    }
    catch (error) {
      // toastr.error("Error: ", error);
    }

  }

  const checkReasonCodes = (reasonCode: string) => {
    if (sortCodeWarnings.includes(reasonCode)) {
      setShowSortCodeField(true);
      setShowWarning(true);
      setShow(true);
    }
    if (payeeNameWarnings.includes(reasonCode) && !payeeAccountNumberWarnings.includes(reasonCode)) {
      setShowPayeeNameField(true);
      setShowWarning(true);
      setShow(true);
    }
    if (payeeNameWarnings.includes(reasonCode) && payeeAccountNumberWarnings.includes(reasonCode)) {
      setShowPayeeNameField(true);
      setShowPayeeAccountNumberField(true);
      setShowWarning(true);
      setShow(true);
    }
    if (payeeAccountTypeWarnings.includes(reasonCode) && !payeeNameWarnings.includes(reasonCode)) {
      setShowPayeeAccountTypeField(true);
      setShowWarning(true);
      setShow(true);
    }
    if (payeeAccountTypeWarnings.includes(reasonCode) && payeeNameWarnings.includes(reasonCode)) {
      setShowPayeeNameField(true);
      setShowPayeeAccountTypeField(true);
      setShowWarning(true);
      setShow(true);
    }
    if (payeeAccountNumberErrors.includes(reasonCode) || secondaryIdentificationErrors.includes(reasonCode)) {
      if (!secondaryIdentificationErrors.includes(reasonCode)) {setShowPayeeAccountNumberField(true)};
      setShowError(true);
      setShow(true);
    }
    if (!payeeAccountNumberErrors.includes(reasonCode) && !sortCodeWarnings.includes(reasonCode) && !payeeNameWarnings.includes(reasonCode) && 
      !payeeAccountTypeWarnings.includes(reasonCode) && !secondaryIdentificationErrors.includes(reasonCode)) {
      callScaChallenge();
    }

  }

  const createEdenredPayee = async () => {
    const payeeDetails: iCreatePayee = {
      user_id: userId,
      payee_name: payeeName,
      sort_code: sortCode,
      account_number: accountNumber,
      account_type: accountType,
      secondary_identification: null
    }

    try {
      const response: iCreatePayeeResponseBodyType = await createPayee(payeeDetails);
      setCreateResponseBody(response);
    } catch (error) {
      setShowEdenredError(true);
      setShow(true);
    }
  }

  useEffect(() => {
    createEdenredPayee();
  }, []);

  useEffect(() => {
    if (createResponseBody) {
      try {
        const reasonCode = createResponseBody.message?.name_verification_result?.reason_code;
        const resultCode = createResponseBody.message?.metadata?.result_code;

        if (edenredErrors.includes(resultCode)) {
          setShowEdenredError(true);
        }
        else if (payeeExistsErrors.includes(resultCode)) {
          setShowSCA(true);
        }
        else {
          checkReasonCodes(reasonCode);
        }
      } catch (error) {
        setShowEdenredError(true);
        setShow(true);
      }
    }
  }, [createResponseBody]);
  
  const handleContinueClick = async () => {
    callScaChallenge();
  }

  const iconComponent = () => (
    showWarning ?
      <span className="hui-sca__header-icon tw-bg-amber-100">
        <Icon type="ExclamationTriangleIcon" classes="!tw-text-amber-700 tw-w-6 tw-h-6" />
      </span>
      : 
      <span className="hui-sca__header-icon tw-bg-red-100">
        <Icon type="ExclamationCircleIcon" classes="!tw-text-red-700 tw-w-6 tw-h-6" />
      </span>
  );

  const sortCodeField = fields.find((f) => f.label === "Sort Code");
  const payeeSortCode = {
    label: sortCodeField.label,
    value: sortCodeField.value,
  };

  const payeeAccountNameField = fields.find((f) => f.label === "Payee Name");
  const payeeAccountName = {
    label: payeeAccountNameField.label,
    value: payeeAccountNameField.value,
  };

  const payeeAccountNumberField = fields.find((f) => f.label === "Payee Account Number");
  const payeeAccountNumber = {
    label: payeeAccountNumberField.label,
    value: payeeAccountNumberField.value,
  };

  const payeeAccountTypeField = fields.find((f) => f.label === "Account Type");
  const payeeAccountType = {
    label: payeeAccountTypeField.label,
    value: payeeAccountTypeField.value,
  };

  const warningSubheading = I18n.t("cop.warning_subheading");

  const errorSubheading = showPayeeAccountNumberField ? I18n.t("cop.error_subheading") : I18n.t("cop.srd_subheading");

  const edenredErrorSubheading = I18n.t("cop.edenred_error_subheading");

  return (
    <>
      <Modal
        open={show}
        setOpen={setShow}
        title={I18n.t("cop.warning_title")}
        includesFooter={false}
        extraHeaderContent={iconComponent()}
        id="cop-modal"
        modalClasses="hui-sca"
      >
        {showWarning && showSortCodeField &&
          <COPWarningModal fields={[payeeSortCode]} subheading={warningSubheading}/>
        }
        {showWarning && showPayeeAccountTypeField && showPayeeNameField &&
          <COPWarningModal fields={[payeeAccountName, payeeAccountTypeField]} subheading={warningSubheading}/>
        }
        {showWarning && showPayeeAccountTypeField && !showPayeeNameField &&
          <COPWarningModal fields={[payeeAccountType]} subheading={warningSubheading}/>
        }
        {showWarning && !showPayeeAccountTypeField && showPayeeNameField && !showPayeeAccountNumberField &&
          <COPWarningModal fields={[payeeAccountName]} subheading={warningSubheading}/>
        }
        {showWarning && showPayeeAccountNumberField && showPayeeNameField &&
          <COPWarningModal fields={[payeeAccountName, payeeAccountNumberField]} subheading={warningSubheading}/>
        }
        {showError && showPayeeAccountNumberField && !showEdenredError &&
          <COPErrorModal fields={[payeeAccountNumber]} subheading={errorSubheading}/>
        }
        {showError && !showPayeeAccountNumberField && !showEdenredError &&
          <COPErrorModal subheading={errorSubheading}/>
        }
        {showEdenredError && !showError &&
          <COPErrorModal subheading={edenredErrorSubheading}/>
        }
        <div className="tw-flex tw-flex-col tw-gap-y-3 tw-m-1">
          {showWarning &&
            <Button
              onClick={handleContinueClick}
              variant="primary"
            >
              {I18n.t("cop.continue_button")}
            </Button>
          }
          <Button onClick={() => setShow(false)} variant="secondary">
            {I18n.t("cop.edit_button")}
          </Button>
        </div>
      </Modal>
      {showSCA && (
        <SCAConfirm actionName={actionName} deviceName={deviceName} challengeId={challengeId} />
      )}
    </>
  );
}

export default COPModal;
