import React, { useEffect } from "react";
import Input from "../../_atoms/input/Input";

type fieldType = { 
  label: string,
  value: string
}

interface iCOPErrorModal {
  fields?: fieldType[];
  subheading: string;
}

const COPErrorModal = ({fields, subheading}: iCOPErrorModal) => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('COPErrorModal mounted');
  })

  return (
    <div className="tw-flex tw-justify-between tw-flex-col">
      <p className="!tw-text-gray-700 tw-mb-4">{subheading}</p>
      {fields?.map(({ label, value }) => (
        <Input
          inputClasses="tw-mb-4"
          key={`${label}-field`}
          disabled
          readOnly
          label={label}
          value={value} 
        />
      ))}
    </div>
  );
};

export default COPErrorModal;
