import React from "react"
// import Button from "@hui/_atoms/button/Button";
import Modal from "@hui/_molecules/modal/Modal";
import I18n from "../../utilities/translations";

interface iInterimConfirmationModal {
  interimConfirmationModalOpen: boolean;
  setInterimConfirmationModalOpen: (boolean) => void;
}

const ctx = "home.call_to_action.delinked.interim_confirmation";
const taxAgencyName = I18n.t("global.tax_collection_authority_short");

const InterimConfirmationModal = ({ interimConfirmationModalOpen, setInterimConfirmationModalOpen }: iInterimConfirmationModal) => {
  const handleConfirm = () => {
    setInterimConfirmationModalOpen(false)
    Turbolinks.visit(window.location.href)
  };

  return (
    <Modal
      closable={true}
      confirmCTA="Done"
      open={interimConfirmationModalOpen}
      setOpen={setInterimConfirmationModalOpen}
      title={I18n.t("title", { scope: ctx })}
      onCancel={() => { Turbolinks.visit(window.location.href) }}
      onConfirm={handleConfirm}
    >
      <div className="list-disc tw-prose-sm tw-prose-grey">
        {I18n.t("paragraph", { scope: ctx, tax_agency: taxAgencyName })}
      </div>
    </Modal>
  )
}

export default InterimConfirmationModal
