import React, { useState } from "react";
import Button from "@hui/_atoms/button/Button";
import ConsentToAct from "@hui/consents/consent_to_act/ConsentToAct";
import Modal from "@hui/_molecules/modal/Modal";
import I18n from "../../utilities/translations";
import { taxAgencyAuthorisationLinked } from "../../API/tax_agency_authorisation.api";
import { taxAgencyAuthorisation as taa } from "../../types/taxAgencyAuthorisation.type";
import { delinkScreens } from "./types";

interface iLinkedModal {
  linkedModalOpen: boolean;
  setFlowState?: (string: delinkScreens) => void;
  setLinkedModalOpen: (boolean) => void;
  taxAgencyAuthorisation: taa;
}

const ctx = "home.call_to_action.delinked.linked"
const taxAgencyName = I18n.t("global.tax_collection_authority_short")

const LinkedModal = ({ linkedModalOpen, setFlowState, setLinkedModalOpen, taxAgencyAuthorisation }: iLinkedModal) => {
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const handleSubmit = async () => {
    try {
      const response = await taxAgencyAuthorisationLinked(taxAgencyAuthorisation.id);

      if (response.success) {
        setLinkedModalOpen(false);
      }
      Turbolinks.visit(window.location.href)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("User unable to update tax agency authorisation", { error });
    }
  };

  const handleBack = () => {
    setLinkedModalOpen(false)
    if (setFlowState) {
      setFlowState("allow_to_relink")
    }
  }

  const handleScrolling = modal => {
    const { scrollTop, scrollHeight } = modal;
    const { height } = modal.getBoundingClientRect();
    const threshold = scrollHeight - height - 50; // Buffer of 50px

    if (scrollTop >= threshold) {
      setConfirmDisabled(false);
    }
  };

  window.addEventListener("scroll", (event) => handleScrolling(event.target), true);

  return (
    <Modal
      closable={true}
      open={linkedModalOpen}
      setOpen={setLinkedModalOpen}
      title={I18n.t("title", { tax_agency: taxAgencyName, scope: ctx })}
      includesFooter={false}
    >
      <ConsentToAct />
      <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row-reverse tw-justify-between mt-1 hnry-dialog-panel-actions">
        <Button variant="primary" disabled={confirmDisabled} onClick={handleSubmit}>{I18n.t("button", { scope: ctx })}</Button>
        <Button variant="secondary" onClick={handleBack}>Back</Button>
      </div>
    </Modal >
  )
}

export default LinkedModal
