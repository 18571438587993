import React from "react";
import { inlineLoader } from "../../utils/base_helper";
import { usePopover } from "../../utils/hooks";

const FooterButtons = ({
  readonly,
  invoiceStatus,
  isImpersonating,
  disabledSave,
  handleNextClick,
  handlePreviewClick,
}) => {
  const showAdminOnlyButton = (invoiceStatus === "SENT" || invoiceStatus === "PART_PAID") && isImpersonating;
  const popoverProps = usePopover({ placement: "top", content: "Total must be greater than $0", otherClasses: "hnry-button hnry-button--primary" });

  return (
    <div className="tw-flex tw-gap-3 tw-flex-wrap-reverse md:tw-flex-row md:tw-justify-between" id="invoices-footer">
      <a href="/invoices" className="hnry-button hnry-button--tertiary md:tw-basis-auto">
        Cancel
      </a>
      {readonly && (
        <button
          type="button"
          className="hnry-button hnry-button--secondary"
          data-toggle="modal"
          data-target="#modal-preview-invoice"
        >
          Preview
        </button>
      )}
      {showAdminOnlyButton ? (
        <button
          type="submit"
          className="hnry-button hnry-button--admin"
          disabled={disabledSave}
        >
          Save {invoiceStatus.replace("_", " ")}
        </button>
      ) : (
        <div className="tw-flex tw-gap-3 tw-w-full md:tw-w-fit tw-flex-col-reverse md:tw-flex-row">
          <div className="tw-flex tw-gap-3 tw-w-full md:tw-w-fit tw-justify-between">
            <button
              id="preview-button"
              className="hnry-button hnry-button--secondary tw-w-full"
              type="button"
              onClick={handlePreviewClick}
            >
              Preview
            </button>
            <button
              className="hnry-button hnry-button--secondary tw-w-full"
              type="submit"
              data-disable-with={inlineLoader}
            >
              Save Draft
            </button>
          </div>
          <button
            className={`hnry-button hnry-button--primary ${disabledSave ? popoverProps.otherClasses : ""}`}
            type="button"
            onClick={handleNextClick}
            disabled={disabledSave}
            {...(disabledSave ? {...popoverProps} : {})}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default FooterButtons;
