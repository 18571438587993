import React, { useMemo, useState, useEffect } from "react";
import Accordion from "../_molecules/accordion/Accordion";
import InputPrice from "../_atoms/input/InputPrice";
import Select from "../_atoms/select/Select";
import Switch from "../_atoms/switch/Switch";
import Badge from "../_atoms/badge/Badge";
import Alert from "../_molecules/alert/Alert";

interface iPrefence{
  name: string,
  cap_frequency: string,
  cap: string,
  percentage: string,
  locked_at: string
}
interface iAdvancedOptionsProps {
  frequencyOptions: string[] ,
  preference: iPrefence,
  isImpersonating: boolean
}
const AdvancedOptions = ({preference, isImpersonating, frequencyOptions}: iAdvancedOptionsProps) => {
  const [cap, setCap] = useState((preference && preference.cap) ? preference.cap : "")
  const [frequency, setFrequency] = useState((preference && preference.cap_frequency) ? preference.cap_frequency : "");
  const [locked, setLocked] = useState(Boolean(preference && preference.locked_at));
  const [percentage, setPercentage] = useState(preference && preference.percentage ? preference.percentage : "");

  const updatePercentage = (e) => {
    setPercentage(e.target.value);
  }

  useEffect(() => {
    const percentageInput = document.getElementById("allocation_preference_percentage");
    if (percentageInput){
      percentageInput.addEventListener("change", updatePercentage);
    }

    return () => { percentageInput.removeEventListener("change", updatePercentage); };
  });
  const handleFrequencyChange = (value: string) => {
    setFrequency(value);
  };
  const handleCapChange = (value: string) => {
    setCap(value);
  };

  const options = useMemo(() => (
    frequencyOptions.map((i) => ({
      name: i, value: i, id: i,
    }))
  ), [frequencyOptions]);

  const { currencySymbol } = window.Hnry.User.jurisdiction;

  return (
    <Accordion  title="Advanced Options" forceMount={true} className="tw-mt-4">
      <fieldset>
        <div className="tw-flex tw-flex-wrap tw-mx-[-15px]">
          <div className="tw-w-full tw-max-w-[100%] sm:tw-max-w-[50%] tw-min-h-[1px] tw-px-3.5 sm:tw-flex-[0_0_50%] tw-relative">
            <InputPrice
              placeholder="0.00"
              name="allocation_preference[cap]"
              id="allocation_preference_cap"
              label="Maximum"
              currencySign={currencySymbol}
              onChange={handleCapChange}
              value={cap}
              disabled={locked && !isImpersonating}
            />
          </div>
          <div className="tw-w-full tw-max-w-[100%] sm:tw-max-w-[50%] tw-min-h-[1px] tw-px-3.5 sm:tw-flex-[0_0_50%] tw-relative sm:tw-mt-0 tw-mt-4 md:tw-mt-0">
            <Select
              id="frequency-dropdown"
              name="allocation_preference[cap_frequency]"
              options={options}
              onChange={handleFrequencyChange}
              selectedValue={frequency}
              disabled={locked && !isImpersonating}
              label="Frequency"
              onClear={() => setFrequency("")}
            />
          </div>
        </div>
      </fieldset>

      {(frequency && cap && percentage) && (
        <div className="tw-pt-4 sm:tw-mt-0">
          <Alert>
            <p className="tw-mb-0 tw-text-sm ">
              Your allocation is set up to deduct <strong>{percentage}%</strong> of each payslip up to a maximum of <strong>{currencySymbol}{cap} {frequency.toLowerCase()}</strong>.
            </p>
          </Alert>
        </div>
      )}
      {isImpersonating && (
        <div className="tw-mt-4 tw-flex tw-gap-x-1">
          <Switch
            checked={locked}
            id="allocation_preference_locked_at"
            label="Lock Allocation"
            onChange={() => { setLocked(!locked); }}
            isAdmin
          />
          <input
            type="hidden"
            id="allocation_preference_locked_at"
            name="allocation_preference[locked_at]"
            value={locked ? "1" : "0"}
          />
          <Badge
            text="Admin only"
            variant="admin"
          />
        </div>
      )}
    </Accordion>
  )
}

export default AdvancedOptions
