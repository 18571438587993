import React from "react";
import Button from "@hui/_atoms/button/Button";
import Modal from "@hui/_molecules/modal/Modal";
import I18n from "../../utilities/translations";
import { taxAgencyAuthorisationIntentionallyDelinked } from "../../API/tax_agency_authorisation.api";
import { taxAgencyAuthorisation as taa } from "../../types/taxAgencyAuthorisation.type";
import { delinkScreens } from "./types";

interface iInterimDelinkedModal {
  intentionallyDelinkedModalOpen: boolean;
  setFlowState: (string: delinkScreens) => void;
  setIntentionallyDelinkedModalOpen: (boolean) => void;
  taxAgencyAuthorisation: taa;
}

const ctx = "home.call_to_action.delinked.intentionally_delinked"
const taxAgencyName = I18n.t("global.tax_collection_authority_short")

const InterimDelinkedModal = ({ intentionallyDelinkedModalOpen, setFlowState, setIntentionallyDelinkedModalOpen, taxAgencyAuthorisation }: iInterimDelinkedModal) => {
  const handleSubmit = async () => {
    try {
      const response = await taxAgencyAuthorisationIntentionallyDelinked(taxAgencyAuthorisation.id);

      if (response.success) {
        setIntentionallyDelinkedModalOpen(false);
      }
      Turbolinks.visit(window.location.href)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("User unable to update tax agency authorisation", { error });
    }
  };

  const handleBack = () => {
    setIntentionallyDelinkedModalOpen(false)
    setFlowState("how_to_proceed")
  }

  return (
    <Modal
      closable={true}
      open={intentionallyDelinkedModalOpen}
      setOpen={setIntentionallyDelinkedModalOpen}
      title={I18n.t("title", { tax_agency: taxAgencyName, scope: ctx })}
      includesFooter={false}
    >
      <div className="list-disc tw-prose-sm tw-prose-grey" dangerouslySetInnerHTML={{ __html: I18n.t("content_html", { scope: ctx, tax_agency: taxAgencyName }) }} />
      <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row-reverse tw-justify-between mt-1 hnry-dialog-panel-actions">
        <Button variant="primary" onClick={handleSubmit}>{I18n.t("button", { scope: ctx })}</Button>
        <Button variant="secondary" onClick={handleBack}>Back</Button>
      </div>
    </Modal >
  )
}

export default InterimDelinkedModal
