import React from "react";
import Datepicker, {
  DatepickerInputProps,
} from "../../inputs/datepicker/datepicker";
import I18n from "../../../utilities/translations";
import { SetDate } from "../../../types/invoices.type";

interface iDateBlock extends DatepickerInputProps {
  label: string;
  requiredLabel?: boolean;
  tooltipText?: string;
}

interface iDateInputs {
  invoiceDate: Date;
  setInvoiceDate: SetDate;

  dueDate: Date;
  setDueDate: SetDate;

  startDate: Date;
  setStartDate: SetDate;

  endDate: Date;
  setEndDate: SetDate;
}

export const DateBlock = ({ label, requiredLabel, tooltipText, ...inputProps }: iDateBlock) => (
  <Datepicker legacyStyles={false} {...{ label, requiredLabel, tooltipText, inputProps }} />
);

const ctx = { scope: "invoices.form" };

const DateInputs = ({
  invoiceDate,
  setInvoiceDate,
  dueDate,
  setDueDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}: iDateInputs) => {
  const startDateTooltipText = I18n.t("start_date", ctx);
  const endDateTooltipText = I18n.t("end_date", ctx);

  return (
    <>
      <DateBlock
        label={I18n.t("invoice_date", ctx)}
        value={invoiceDate}
        name="invoice[invoice_date]"
        onChange={(date) => setInvoiceDate(date)}
        requiredLabel={true}
      />
      <DateBlock
        label={I18n.t("due_date", ctx)}
        value={dueDate}
        name="invoice[due_date]"
        onChange={(date) => setDueDate(date)}
        requiredLabel={true}
      />

      <DateBlock
        label={I18n.t("period_start_date", ctx)}
        value={startDate}
        name="invoice[period_start_date]"
        onChange={(date) => setStartDate(date)}
        tooltipText={startDateTooltipText}
      />
      <DateBlock
        label={I18n.t("period_end_date", ctx)}
        value={endDate}
        name="invoice[period_end_date]"
        onChange={(date) => setEndDate(date)}
        tooltipText={endDateTooltipText}
      />
    </>
  )
};

export default DateInputs;
