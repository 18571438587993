import React from "react";
import PropTypes from "prop-types";
import { format, parse } from "date-fns";
import logo from "../../../../assets/images/hnry-logo-name-only.svg";

import { useInvoiceQuoteContext } from "../InvoiceQuoteContext";
import { Modal, ModalBody, ModalFooter } from "../../modal/Modal";
import Footer from "./InvoiceEmail/Footer";
import I18n from "../../../utilities/translations";
import NotSendingWarning from "../../invoices/NotSendingWarning";

import { timeZoneType } from "../../../types";

const InvoiceEmailModal = ({
  client,
  invoiceClientSelected,
  timeZone,
  clientName,
  tradingName,
  userName
}) => {
  // Get all the relevant details for the email from Context,
  // and if it's not loaded (i.e: one of the items doesn't exist)
  // return null and the component will rerender when it isn't null
  const {
    invoiceObject,
    otherProps,
    grandTotal,
    startDate,
    endDate,
    dueDate,
    scheduleDate,
    scheduleTime,
    hideLegalName
  } = useInvoiceQuoteContext();
  if (!invoiceObject) return null;

  // Extact some more details out as top level variables
  // as they will be passed in to the various components that
  // make up the email modal
  const { invoice_number } = invoiceObject;
  const {
    attach_pdf_invoice,
    credit_card_minimum_amount,
    credit_card_surcharge,
    custom_logo,
    destination_email_addresses,
    filename,
    jurisdiction,
    locale,
    payable_by_credit_card,
    text,
  } = otherProps;

  return (
    <Modal
      id="modal-invoice-email"
      title={`Invoice ${invoice_number} for ${client}`}
      closable
    >
      <ModalBody>
        <NotSendingWarning
          client={client}
          invoiceClientSelected={invoiceClientSelected}
          onModal
        />
        <DestinationAlert
          destination={destination_email_addresses}
          scheduleDate={scheduleDate}
          scheduleTime={scheduleTime}
          timeZone={timeZone}
        />
        <CustomLogo
          customLogo={custom_logo}
          tradingName={tradingName}
          userName={userName}
        />
        <OpeningParagraph
          invoiceLocation={
            attach_pdf_invoice ? "attached" : "below the link for the"
          }
          clientName={clientName}
          invoiceNumber={invoice_number}
          startDate={startDate}
          endDate={endDate}
        />
        <InvoiceLink />
        <PayNowLink
          payableByCreditCard={
            payable_by_credit_card && grandTotal >= credit_card_minimum_amount
          }
          jurisdiction={jurisdiction}
          surcharge={credit_card_surcharge}
          locale={locale}
        />
        <MessageFooter
          dueDate={dueDate}
          tradingName={tradingName}
          userName={userName}
          hideLegalName={hideLegalName}
          jurisdiction={jurisdiction}
        />
        <ShowAttachment filename={filename} />
        <SocialLinks logo={logo} text={text} />
      </ModalBody>
      <ModalFooter>
        <Footer scheduleDate={scheduleDate} scheduleTime={scheduleTime} />
      </ModalFooter>
    </Modal>
  );
};

// An image of the User's custom logo
const CustomLogo = ({ customLogo, tradingName, userName }) =>
  customLogo ? (
    <img
      src={customLogo}
      height="80px"
      className="mb-2"
      alt={`${tradingName || userName} Logo`}
    />
  ) : null;

// An alert showing whom the Invoice will be sent to
const DestinationAlert = ({
  destination,
  scheduleDate,
  scheduleTime,
  timeZone,
}) => {
  const content = () => {
    let text = `This email will be sent to: ${destination}`;
    if (scheduleDate instanceof Date && scheduleTime) {
      text = `${text} at ${format(parse(scheduleTime, "HH:mm", new Date()), "h:mma")} on ${format(
        scheduleDate,
        "d/M/yyyy",
      )}`;
      if (!timeZone.browserInTimezone) {
        text = `${text} (${timeZone.friendlyName})`;
      }
    }

    return text;
  };

  return (
    <div className="alert alert-info" role="alert">
      <p>{content()}</p>
    </div>
  );
};

DestinationAlert.propTypes = {
  destination: PropTypes.string.isRequired,
  scheduleDate: PropTypes.instanceOf(Date),
  scheduleTime: PropTypes.string,
  timeZone: timeZoneType.isRequired,
};

// Opening paragraph with all the general details of the invoice
// that would go in the email body
const OpeningParagraph = ({
  clientName,
  invoiceLocation,
  invoiceNumber,
  startDate,
  endDate,
}) => (
  <>
    <p className="tw-text-sm">Hi{clientName ? ` ${clientName}` : ""},</p>
    <p className="tw-text-sm">
      Please find {invoiceLocation} invoice {invoiceNumber}
      <PeriodDates {...{ startDate, endDate }} />.
    </p>
  </>
);

// Displays the period start and period end dates for the Invoice,
// formatted as `from DD/MM/YYYY to DD/MM/YYYY`
const PeriodDates = ({ startDate, endDate }) =>
  startDate && endDate ? (
    <span>
      {" "}for the period of {startDate.toLocaleDateString("en-GB")} to{" "}
      {endDate.toLocaleDateString("en-GB")}
    </span>
  ) : null;
// Displays a dud link that would go to the Hnry app to preview the Invoice
const InvoiceLink = () => (
  <p className="tw-text-sm">
    <button type="button" className="tw-pl-0 tw-text-blue-600" disabled>
      View invoice
    </button>
  </p>
);

// Displays a link that goes sto the Hnry app to preview the Invoice
// To be honest i don't know how this is different to `InvoiceLink`
const PayNowLink = ({
  payableByCreditCard,
  surcharge,
  jurisdiction,
  locale,
}) =>
  payableByCreditCard ? (
    <p className="tw-text-smt">
      <button type="button" className="tw-text-blue-600 pl-0" disabled>
        Pay now via card
      </button>
      {jurisdiction == "au" && (
        <span className="small">
          <br />
          {I18n.t("invoices.convenience_fee", { locale, surcharge })}
        </span>
      )}
    </p>
  ) : null;

// The sign-off for the email
const MessageFooter = ({
  dueDate,
  tradingName,
  userName,
  hideLegalName,
  jurisdiction
}) =>
  dueDate ? (
    <>
      <p className="tw-text-sm tw-mb-4">
        The amount outstanding is due on or before{" "}
        {dueDate.toLocaleDateString("en-GB")}.
      </p>
      {jurisdiction == "nz" && (
        <p className="tw-text-sm tw-mb-4">
          {I18n.t("invoice_mailer.send_invoice.cop_paragraph")} <a href={I18n.t("invoice_mailer.send_invoice.cop_link")} target="_blank" rel="noreferrer">{I18n.t("invoice_mailer.send_invoice.cop_link_text")}</a>.
        </p>
      )}
      
      Thanks,
      <br /><br />
      {!hideLegalName && (
        <>
          <b className="tw-text-brand-green">{userName}</b>
          <br />
        </>
      )}
      <b className="tw-text-brand-green">{tradingName}</b>
    </>
  ) : null;

// Determine whether to show a link to a PDF download or not
const ShowAttachment = ({ attachPDF, filename }) =>
  attachPDF ? (
    <p className="quite-small-text">
      <i
        className="fa fa-file-pdf-o fa-4x"
        aria-hidden="true"
        style={{ color: "red" }}
      />
      <br />
      {filename}
    </p>
  ) : null;

// Social links for the marketing site, twitter, and facebook
const SocialLinks = ({ logo, text }) => (
  <>
    <hr className="tw-mt-4" />
    <p className="tw-text-sm">
      <img
        src={logo}
        width="180px"
        height="40px"
        className="tw--ml-6"
        alt="Hnry Logo"
      />
      <b className="tw-ml-4" style={{ color: "#242659" }}>
        Powered by Hnry
      </b>
    </p>
    <p className="tw-text-sm">
      <a href={text.marketing_site} className="mx-1 tw-text-blue-600">
        {text.marketing_site_short}
      </a>
      |
      <a href={text.twitter} className="mx-1 tw-text-blue-600">
        {text.twitter_short}
      </a>
      |
      <a href={text.facebook} className="mx-1 tw-text-blue-600">
        {text.facebook_short}
      </a>
    </p>
  </>
);

export default InvoiceEmailModal;
