import React from "react"
import Button from "@hui/_atoms/button/Button";
import Modal from "@hui/_molecules/modal/Modal";
import I18n from "../../utilities/translations";
import { delinkScreens } from "./types";

interface iAllowToRelinkModal {
  allowToRelinkModalOpen: boolean;
  setAllowToRelinkModalOpen: (boolean) => void;
  setFlowState: (string: delinkScreens) => void;
  salesTaxRegistered: boolean;
}

const translationOptions = {
  scope: "home.call_to_action.delinked.allow_to_relink",
  tax_agency: I18n.t("global.tax_collection_authority_short"),
  sales_tax: I18n.t("global.sales_tax")
}

const AllowToRelinkModal = ({ allowToRelinkModalOpen, setAllowToRelinkModalOpen, setFlowState, salesTaxRegistered }: iAllowToRelinkModal) => {
  const handleYes = () => {
    setFlowState("linked")
    setAllowToRelinkModalOpen(false)
  };

  const handleNo = () => {
    const nextState = salesTaxRegistered ? "sales_tax_proceed" : "how_to_proceed"
    setFlowState(nextState)
    setAllowToRelinkModalOpen(false)
  }

  const modalList = salesTaxRegistered ?
    I18n.t("sales_tax_list_html", translationOptions) : 
    I18n.t("no_sales_tax_list_html", translationOptions)

  return (
    <Modal
      closable={true}
      open={allowToRelinkModalOpen}
      setOpen={setAllowToRelinkModalOpen}
      title={I18n.t("title", translationOptions)}
      includesFooter={false}
    >
      <div>
        <p>
          {I18n.t("paragraph", translationOptions)}
        </p>
        {/* eslint-disable-next-line xss/no-mixed-html */}
        <div className="list-disc tw-prose-sm tw-prose-grey" dangerouslySetInnerHTML={{ __html: modalList }}></div>
      </div>
      <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row-reverse tw-justify-between mt-1 hnry-dialog-panel-actions">
        <Button variant="primary" onClick={handleYes}>{I18n.t("yes_button", translationOptions)}</Button>
        <Button variant="secondary" onClick={handleNo}>{I18n.t("no_button", translationOptions)}</Button>
      </div>
    </Modal>
  )
}

export default AllowToRelinkModal
