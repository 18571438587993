import React from "react"
import Button from "@hui/_atoms/button/Button";
import Modal from "@hui/_molecules/modal/Modal";
import I18n from "../../utilities/translations";
import { delinkScreens } from "./types";

interface iSalesTaxProceedModal {
  salesTaxProceedModalOpen: boolean;
  setSalesTaxProceedModalOpen: (boolean) => void;
  setFlowState: (string: delinkScreens) => void;
}

const translationOptions = {
  scope: "home.call_to_action.delinked.sales_tax_proceed",
  tax_agency: I18n.t("global.tax_collection_authority_short"),
  sales_tax: I18n.t("global.sales_tax")
}

const SalesTaxProceedModal = ({ salesTaxProceedModalOpen, setSalesTaxProceedModalOpen, setFlowState }: iSalesTaxProceedModal) => {
  const handleYes = () => {
    setFlowState("sales_tax_only_consent")
    setSalesTaxProceedModalOpen(false)
  };

  const handleNo = () => {
    // setFlowState("tsk") What actually happens here is not yet in the designs
    setSalesTaxProceedModalOpen(false)
  }

  return (
    <Modal
      closable={true}
      includesFooter={false}
      open={salesTaxProceedModalOpen}
      setOpen={setSalesTaxProceedModalOpen}
      title={I18n.t("title", translationOptions)}
    >
      <div>
        <p>
          {I18n.t("paragraph", translationOptions)}
        </p>
      </div>
      <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row-reverse tw-justify-between mt-1 hnry-dialog-panel-actions">
        <Button variant="primary" onClick={handleYes}>{I18n.t("yes_button", translationOptions)}</Button>
        <Button variant="secondary" onClick={handleNo}>{I18n.t("no_button", translationOptions)}</Button>
      </div>
    </Modal>
  )
}

export default SalesTaxProceedModal
