import React from "react";
import BlockingModal from "@hui/_molecules/blocking_modal/BlockingModal";
import I18n from "../../../utilities/translations";
import { iWaitlistModal } from "../interface/WaitlistInterfaces";
import BrowserContent from "./BrowserContent";
import moreActions from "./MenuActions";
import NativeAppContent from "./NativeContent";

const WaitlistModal = ({
  nativeApp,
  appPlatformName,
  headerImagePath,
}: iWaitlistModal) => (
  <BlockingModal
    hideOverlay={true}
    title={I18n.t("home.uk_waitlist.title")}
    headerImagePath={headerImagePath}
    moreActions={moreActions({ nativeApp })}
    modal={false}
    onOpenAutoFocus={(event) => event.preventDefault()}
  >
    {nativeApp ? <NativeAppContent /> : <BrowserContent appPlatformName={appPlatformName} />}
  </BlockingModal>

);

export default WaitlistModal;
